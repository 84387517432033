export { default as OrgAddEdit } from './OrgAddEdit.vue'
export { default as OrgAddEditStep1 } from './OrgAddEditStep1'
export { default as OrgAddEditStep2 } from './OrgAddEditStep2'
export { default as OrgAddEditStep3 } from './OrgAddEditStep3'

/**
 *
 * @param {Object} args
 * @param {Number} args.step
 * @returns {object} models
 */
export function getFormModel({ step }) {
  const models = {
    1: {
      name: '',
      alias: '',
      avatar: '',
      phone_number: '',

      country: '',
      city: '',
      zip_code: '',
      address: '',

      default_fleet: null,
      organization_category: '',

      domain: '',
      admin_domain: '',
    },

    2: {
      subscription: '',
      vehicle_type: [],
    },

    3: {
      payment: {
        payment_gateway: '',
        client_id: '',
        secret_key: '',
        app_key: '',
        extra_key: '',
        sandbox_mode: false,
        sandbox_client_id: '',
        sandbox_secret_key: '',
        sandbox_app_key: '',
      },

      paymentId: '',

      domain: [],
      regular: {
        is_wallet_payment_enabled: true,
        is_cash_payment_enabled: true,
        is_on_ride_payment_enabled: false,
        max_credit_card_count: 10,
        otp_rate_limit: 4,
        otp_rate_mode: 's',
        segway_client_id: '',
        segway_client_secret: '',
        segway_portal_base_urL: '',
      },

      link: {
        play_store_link: '',
        apple_store_link: '',
        website_link: '',
        email_address: '',
      },
    },

    4: {
      full_name: '',
      username: '',
      email: '',
      phone_number: '',
      owner_expiry_date: '',
    },
  }

  return models[step]
}
