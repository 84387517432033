<template>
  <BaseLayout>
    <ContentSection :spacing="false">
      <div class="content-spacing">
        <TitlePlus
          title="Organization Management"
          :hide-plus="!$user.is_superadmin"
          @plus="onOpenES({ primaryKey: null })"
          class="mb-10"
        />
      </div>
      <div class="full-mode">
        <FSTable
          :fst-id="`Organizations`"
          :endpoint="indexDataEndpoint"
          :headers="tableHeaders"
        >
          <template #default="{ data }">
            <FSTableRow
              v-for="(item, itemIndex) in data"
              :key="`fs-table-row-${itemIndex}`"
              textFallbackAlways
            >
              <FSTableRowItem :text="item.id" :truncate="-5" />

              <FSTableRowItem
                :text="item.name"
                :to="{
                  name: 'ViewOrganizationProfile',
                  params: { id: item.id },
                }"
              />

              <FSTableRowItem :text="item.created_at" date />
              <FSTableRowItem :text="item.country.name" />

              <FSTableRowItem>
                <div class="status-draft-active" v-if="item.status == 'I'">
                  <span>INACTIVE</span>
                </div>
                <div class="status-active" v-if="item.status == 'A'">
                  <span>ACTIVE</span>
                </div>
                <div class="status-draft-active" v-if="item.status == 'D'">
                  <span>DRAFT</span>
                </div>
              </FSTableRowItem>

              <FSTableRowItem>
                <div class="flex items-center">
                  <div @click="onOpenES({ primaryKey: item.id })">
                    <oto-edit-icon />
                  </div>
                  <template v-if="$user.is_superadmin">
                    <MoreActionsDropdown
                      :primaryKey="item.id"
                      :currentVal="item.status"
                      onOffMethod="PATCH"
                      :onOffEndpoint="getActionsEndpoint(item.id)"
                      actionOnKey="status"
                      actionOnVal="A"
                      @actionOn="item.status = 'A'"
                      actionOffKey="status"
                      actionOffVal="I"
                      @actionOff="item.status = 'I'"
                      :actionDeleteEnabled="true"
                      actionDeleteKey="delete"
                      actionDeleteVal=""
                      actionDeleteMethod="PATCH"
                      :actionDeleteEndpoint="getActionsEndpoint(item.id)"
                      passwordKey="password"
                      @refresh="$store.dispatch('fsTable/fetchData')"
                    />
                  </template>
                </div>
              </FSTableRowItem>
            </FSTableRow>
          </template>
        </FSTable>
      </div>

      <div class="responsive-mode">
        <FSTable
          :fst-id="`Organizations`"
          :endpoint="indexDataEndpoint"
          :headers="resTableHeaders"
        >
          <template #default="{ data }">
            <template v-for="(item, index) in data">
              <FSTableRow :key="`fs-table-row-${index}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(index)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(index)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem :text="item.id" :truncate="-5" />

                <FSTableRowItem
                  :text="item.name"
                  :to="{
                    name: 'ViewOrganizationProfile',
                    params: { id: item.id },
                  }"
                />

                <FSTableRowItem :text="item.country.name" />
              </FSTableRow>
              <FSTableRow v-if="opened.includes(index)" :key="index">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">STATUS</div>
                    <div class="col-span-5 right-text">
                      <div
                        class="status-draft-active"
                        v-if="item.status == 'I'"
                      >
                        <span>INACTIVE</span>
                      </div>
                      <div class="status-active" v-if="item.status == 'A'">
                        <span>ACTIVE</span>
                      </div>
                      <div
                        class="status-draft-active"
                        v-if="item.status == 'D'"
                      >
                        <span>DRAFT</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">CREATED AT</div>
                    <div class="col-span-5 right-text">
                      {{ item.created_at | friendlyDateTime }}
                    </div>
                  </div>

                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">ACTIONS</div>
                    <div class="col-span-5 right-text">
                      <div class="flex items-center">
                        <div @click="onOpenES({ primaryKey: item.id })">
                          <oto-edit-icon />
                        </div>
                        <template v-if="$user.is_superadmin">
                          <MoreActionsDropdown
                            :primaryKey="item.id"
                            :currentVal="item.status"
                            onOffMethod="PATCH"
                            :onOffEndpoint="getActionsEndpoint(item.id)"
                            actionOnKey="status"
                            actionOnVal="A"
                            @actionOn="item.status = 'A'"
                            actionOffKey="status"
                            actionOffVal="I"
                            @actionOff="item.status = 'I'"
                            :actionDeleteEnabled="true"
                            actionDeleteKey="delete"
                            actionDeleteVal=""
                            actionDeleteMethod="PATCH"
                            :actionDeleteEndpoint="getActionsEndpoint(item.id)"
                            passwordKey="password"
                            @refresh="$store.dispatch('fsTable/fetchData')"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </FSTable>
      </div>
    </ContentSection>
    <OrgAddEdit
      :es-id="addEdit.esId"
      :stepper-mode="addEdit.stepperMode"
      :stepper-step="addEdit.stepperStep"
      :primary-key="addEdit.primaryKey"
      :raw-data="addEdit.rawData"
      :busy="addEdit.busy"
      @refresh="$store.dispatch('fsTable/fetchData')"
    />
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { OrgAddEdit } from '@/composites/organization'
import { OrganizationConfig } from '@/config/OrganizationConfig'
import { useEndpoints } from '@/composables'
export default {
  name: 'Organizations',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    MoreActionsDropdown,
    OtoEditIcon,
    OrgAddEdit,
  },
  data() {
    return {
      indexDataEndpoint: OrganizationConfig.api.index,
      tableHeaders: [
        { text: 'ID', width: '10%', sort: null },
        { text: 'Name', width: '15%', sort: 'name' },
        { text: 'Created At', width: '20%', sort: 'created_at' },
        { text: 'Country', width: '20%', sort: null },
        { text: 'Active Status', width: '15%', sort: 'status' },
        { text: 'Actions', width: '15%', sort: null },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'ID', width: '10%', sort: null },
        { text: 'Name', width: '40%', sort: 'name' },
        { text: 'Country', width: '40%', sort: null },
      ],
      opened: [],
      addEdit: {
        // EdgeStack component
        esId: 'org-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
      },
    }
  },
  methods: {
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.addEdit.busy = true
        this.$edgeStack.open(this.addEdit.esId)

        const orgReq = this.$http.get(
          useEndpoints.organization.details(primaryKey)
        )

        const requests = [orgReq]

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              let data = {}
              const [orgRes] = responses
              console.log(orgRes.data, 'orgRes')

              data = { ...orgRes.data }

              this.addEdit.rawData = data
              this.addEdit.primaryKey = primaryKey
              this.addEdit.stepperMode = 'free'

              this.addEdit.busy = false
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.addEdit.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.addEdit.stepperMode = 'strict'

      this.addEdit.busy = false
      this.addEdit.rawData = null
      this.addEdit.primaryKey = null

      this.$edgeStack.open(this.addEdit.esId)
      // this.$xStepper.navigate(this.addEdit.esId).to(4)
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
        console.log(
          'odvhut-splice',
          id,
          index,
          'data',
          this.opened.includes(index)
        )
      } else {
        this.opened.push(id)
        console.log(
          'odvhut-push',
          id,
          index,
          'data',
          this.opened.includes(index)
        )
      }
      console.log('odvhut-out', id, index, 'data', this.opened.includes(index))
    },
    getActionsEndpoint(id) {
      return OrganizationConfig.api.update(id)
    },
  },
}
</script>
<style lang="postcss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
.status-active {
  display: flex;
  width: 90px;
  height: 20px;
  background: #04bc34;
  //float: right;
  text-align: center;
  border-radius: 5px;
}
.status-active span {
  font-size: 11px;
  color: white;
  margin: auto;
}
.status-no-active {
  width: 90px;
  height: 20px;
  background: #ff0500;
  //float: right;
  text-align: center;
  border-radius: 5px;
  display: flex;
}
.status-no-active span {
  font-size: 11px;
  color: white;
  margin: auto;
}
.status-draft-active {
  width: 90px;
  height: 20px;
  background: #b4b9bc;
  //float: right;
  text-align: center;
  border-radius: 5px;
  display: flex;
}
.status-draft-active span {
  font-size: 11px;
  color: white;
  margin: auto;
}
</style>
