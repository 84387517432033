<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <!-- payment -->
      <section>
        <!-- row-1 -->
        <div>
          <AppInput
            v-model="form.payment.payment_gateway"
            type="richselect"
            name="Payment Gateway"
            label="Payment Gateway"
            rules="required"
            placeholder="Select payment gateway"
            text-attribute="name"
            value-attribute="id"
            :options="paymentGateways"
            hide-search-box
          />
        </div>
        <!-- /row-1 -->

        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.payment.sandbox_mode"
            :label="`Enable Live Mode`"
            :name="`Enable Live Mode`"
            wrapped
          />
        </div>

        <!-- row-2 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.payment.client_id"
            type="password"
            name="Client ID"
            :rules="form.payment.sandbox_mode ? '' : 'required'"
            label="Client ID"
            placeholder="Client ID"
          />

          <AppInput
            v-model="form.payment.sandbox_client_id"
            type="password"
            name="Live Client ID"
            :rules="form.payment.sandbox_mode ? 'required' : ''"
            label="Live Client ID"
            placeholder="Live Client ID"
          />
        </div>
        <!-- /row-3 -->

        <!-- row-3 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.payment.secret_key"
            type="password"
            name="Secret Key"
            :rules="form.payment.sandbox_mode ? '' : 'required'"
            label="Secret Key"
            placeholder="Secret Key"
          />

          <AppInput
            v-model="form.payment.sandbox_secret_key"
            type="password"
            name="Live Secret Key"
            :rules="form.payment.sandbox_mode ? 'required' : ''"
            label="Live Secret Key"
            placeholder="Live Secret Key"
          />
        </div>
        <!-- /row-3 -->

        <!-- row-4 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.payment.app_key"
            type="password"
            name="App Key"
            label="App Key"
            rules=""
            placeholder="App Key"
          />

          <AppInput
            v-model="form.payment.sandbox_app_key"
            type="password"
            name="Live App Key"
            label="Live App Key"
            rules=""
            placeholder="Live App Key"
          />
        </div>
        <!-- /row-4 -->

        <div>
          <AppInput
            v-model="form.payment.extra_key"
            type="password"
            name="Extra Key"
            rules=""
            label="Extra Key"
            placeholder="Extra Key"
          />
        </div>
        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.regular.is_wallet_payment_enabled"
            :label="`Enable Wallet Payment Method `"
            :name="`Enable Wallet Payment Method `"
            wrapped
          />
          <TCheckbox
            v-model="form.regular.is_cash_payment_enabled"
            :label="`Enable Cash Payment Method `"
            :name="`Enable Cash Payment Method `"
            wrapped
          />
        </div>
        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.regular.is_on_ride_payment_enabled"
            :label="`Enable Ride Payment`"
            :name="`Enable Ride Payment`"
            wrapped
          />
        </div>
        <div>
          <AppInput
            rules=""
            type="number"
            name="Max Credit Card Count"
            label="Max Credit Card Count"
            placeholder="e.g. 10"
            v-model="form.regular.max_credit_card_count"
          />
        </div>
      </section>
      <!-- /payment -->
      <!-- security -->
      <section>
        <div class="my-5 text-sm font-bold text-gray-500">
          <span>Security</span>
        </div>
        <div>
          <AppInput
            rules=""
            type="number"
            name="OTP Rate limit"
            label="OTP Rate limit (Optional)"
            placeholder="e.g. 4"
            v-model="form.regular.otp_rate_limit"
          />
        </div>
        <div>
          <AppInput
            v-model="form.regular.otp_rate_mode"
            type="richselect"
            name="Rate Unit"
            label="Rate Unit (Optional)"
            rules=""
            placeholder="Select Mode"
            text-attribute="text"
            value-attribute="value"
            :options="getOtpRateModeOptions"
            hide-search-box
          />
        </div>
      </section>
      <!-- /security -->

      <!-- domains -->
      <section>
        <!-- <div class="section-name" v-text="`Domains`" /> -->
        <div class="flex items-center my-5 text-sm font-bold text-gray-500">
          <span>Manage IoT</span>
          <div
            class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
            @click="onAddLockTypeField"
          >
            <i class="fas fa-plus-circle" style="color: black"></i>
          </div>
        </div>

        <!-- {{ lockTypes }} -->
        <div
          class="flex items-center"
          v-for="(item, index) in lockTypes"
          :key="index"
        >
          <div class="flex gap-2 items-center w-11/12">
            <div class="w-4/12">
              <AppInput
                v-model="item.type"
                type="richselect"
                :name="`IoT Type ${index}`"
                label="Type"
                value-attribute="value"
                text-attribute="display_name"
                placeholder="Select IoT Type"
                :options="lockIots"
                hide-search-box
              />
            </div>

            <div class="w-4/12">
              <AppInput
                v-model="item.domain"
                type="text"
                :name="`Domain ${index}`"
                rules=""
                label="Domain"
                placeholder="Write a domain"
              />
            </div>

            <div class="w-4/12">
              <AppInput
                v-model="item.port"
                type="number"
                :name="`Port ${index}`"
                rules=""
                label="Port"
                placeholder="Write a port"
              />
            </div>
          </div>

          <div class="flex justify-center w-1/12 item-center">
            <div
              class="col-span-1 cursor-pointer focus:text-gray-400"
              @click="onRemoveLockTypeField(index)"
            >
              <i class="fas fa-minus-circle" style="color: #d90a20"></i>
            </div>
          </div>
        </div>
      </section>
      <!-- /domains -->

      <!-- Segway Dashboard Configuration -->
      <section v-if="isSegwayIotType">
        <div class="my-5 text-sm font-bold text-gray-500">
          <span>Segway Dashboard Configuration</span>
        </div>
        <div>
          <AppInput
            rules="required"
            type="String"
            name="Segway Client Id"
            label="Segway Client Id"
            placeholder="e.g. 4xhI4fk"
            v-model="form.regular.segway_client_id"
          />
        </div>
        <div>
          <AppInput
            rules="required"
            type="String"
            name="Segway Secret Key"
            label="Segway Secret Key"
            placeholder="e.g. HkiC-45k"
            v-model="form.regular.segway_client_secret"
          />
        </div>
        <div>
          <AppInput
            rules="required"
            type="String"
            name="Segway Portal Base URL"
            label="Segway Portal Base URL"
            placeholder="e.g. portal.website.com"
            v-model="form.regular.segway_portal_base_urL"
          />
        </div>
      </section>
      <!-- /Segway Dashboard Configuration -->

      <!-- web-links -->
      <section>
        <div class="my-5 section-name" v-text="`Web Links`" />

        <div class="flex items-center">
          <AppInput
            rules=""
            name="Play Store"
            label="Play Store"
            placeholder="e.g. https://play.google.com/store/apps"
            v-model="form.link.play_store_link"
          />

          <div class="web-link-icon">
            <i class="fab fa-google-play"></i>
          </div>
        </div>

        <div class="flex items-center">
          <AppInput
            rules=""
            name="Apple Store"
            label="Apple Store"
            placeholder="e.g. https://apple.com/ios/store/apps"
            v-model="form.link.apple_store_link"
          />
          <div class="web-link-icon">
            <i class="fab fa-apple"></i>
          </div>
        </div>

        <div class="flex items-center">
          <AppInput
            rules=""
            label="Website"
            placeholder="e.g. https://example.com"
            v-model="form.link.website_link"
          />

          <div class="web-link-icon">
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
        </div>

        <div class="flex items-center">
          <AppInput
            rules="email"
            label="Email"
            placeholder="e.g. john@example.com"
            v-model="form.link.email_address"
          />

          <div class="web-link-icon">
            <i class="fas fa-envelope"></i>
          </div>
        </div>
      </section>
      <!-- /web-links -->

      <button type="submit" ref="submitButton" class="hidden">Save</button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'
// import { getFormModel } from '.'
// import differenceBy from 'lodash/differenceBy';
export default {
  name: 'OrgAddEditStep3',

  components: {},

  props: {
    lockIots: {
      type: Array,
      required: true,
    },
    paymentGateways: {
      type: Array,
      required: true,
    },
    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    // const { payment, link, domain } = getFormModel({ step: 3 })
    // console.log(payment, link, domain, 'form3')

    return {
      isLoading: false,
      form: {
        payment: {
          payment_gateway: '',
          client_id: '',
          secret_key: '',
          app_key: '',
          extra_key: '',
          sandbox_mode: false,
          sandbox_client_id: '',
          sandbox_secret_key: '',
          sandbox_app_key: '',
        },
        regular: {
          is_wallet_payment_enabled: true,
          is_cash_payment_enabled: true,
          is_on_ride_payment_enabled: false,
          max_credit_card_count: 10,
          otp_rate_limit: 4,
          otp_rate_mode: 's',
          segway_client_id: '',
          segway_client_secret: '',
          segway_portal_base_urL: '',
        },
        link: {},
        domain: {},
      },

      lockTypes: [],
      toDeleteLockTypes: [],
      isSegwayIotType: false,
    }
  },

  computed: {
    getLockIots() {
      const results = this.lockIots.filter(
        ({ value: id1 }) => !this.lockTypes.some(({ type: id2 }) => id2 === id1)
      )

      return results

      // return this.lockIots.filter((el) => {
      //   return this.lockTypes.findIndex((e) => e.type === el.value)
      // })
    },
    getOtpRateModeOptions() {
      return [
        { value: 's', text: 'Per Second' },
        { value: 'm', text: 'Per Minute' },
        { value: 'h', text: 'Per Hour' },
      ]
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      deep: true,
      immediate: true,
      handler(data) {
        if (data) {
          // todo: if domains are < 1 it might be in draft status
          // if paymentId is empty, it hasn't been created yet (probably in draft status)
          // links are optional fields
          const { payment, paymentId, link, regular } = data
          this.form = { payment, paymentId, link, regular }
          console.log(this.form, 'ff3')
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 3, data: this.form })
      },
    },
    lockTypes: {
      deep: true,
      immediate: true,
      handler(updatedData) {
        this.isSegwayIotType = updatedData.some((iot) => {
          const type = iot.type.split('_')[0]
          if (type === 'SEGWAY') {
            return true
          } else {
            return false
          }
        })
      },
    },
  },
  async mounted() {
    await this.requestOrganizationVehicleIotTypeSettings()
  },
  methods: {
    async requestOrganizationVehicleIotTypeSettings() {
      let url = useEndpoints.organization.vehicleTypeSettings.index(
        this.primaryKey
      )
      this.isLoading = true
      this.lockTypes = []

      await this.$http
        .get(url)
        .then((res) => {
          console.log('IoT Types', res.data.data)
          this.lockTypes = res.data?.data.map((entry) => {
            return {
              id: entry.id,
              type: entry.iot_category,
              name: entry.iot_category_name,
              domain: entry.domain ? entry.domain : '',
              port: entry.port,
            }
          })
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onAddLockTypeField() {
      this.lockTypes.unshift({ type: '', domain: '', port: '' })
    },
    onRemoveLockTypeField(index) {
      if (this.isEditing) {
        let toRemoveType = this.lockTypes[index]
        if (
          this.lockTypes.filter((entry) => entry.type === toRemoveType.type)
            .length > 0
        )
          this.toDeleteLockTypes.push(this.lockTypes[index])
      }
      this.lockTypes.splice(index, 1)
    },

    async submit() {
      await this.$refs.submitButton.click()
    },
    async submitStep() {
      /**
       * Payment
       *
       * in draft status -> the payment gateway might not been created,
       * meaning there's no paymentId, in that case, it need to be created.
       */
      const paymentId = this.form.paymentId

      const paymentUrl = paymentId
        ? useEndpoints.paymentGateway.update(paymentId)
        : useEndpoints.paymentGateway.create()

      const paymentMethod = paymentId ? 'PATCH' : 'POST'

      console.log({ paymentMethod })

      const paymentData = new FormData()
      const paymentFormData = { ...this.form.payment }
      paymentFormData.organization = this.primaryKey

      for (const k in paymentFormData) {
        paymentData.append(k, paymentFormData[k])
      }

      const paymentReq = this.$http({
        url: paymentUrl,
        method: paymentMethod,
        data: paymentData,
      })

      /**
       * Regular
       *
       * Regular are always org patch req, if primaryKey is there.
       * The user won't make to this step if there's no primary key.
       * Skipping the primary key check.
       */
      const regularUrl = useEndpoints.organization.update(this.primaryKey)
      const regularMethod = 'PATCH'
      const regularData = new FormData()
      for (const k in this.form.regular) {
        console.log('regular', k, this.form.regular[k])
        regularData.append(k, this.form.regular[k])
      }

      const regularReq = this.$http({
        url: regularUrl,
        method: regularMethod,
        data: regularData,
      })

      /**
       * Links
       *
       * Links are always org patch req, if primaryKey is there.
       * The user won't make to this step if there's no primary key.
       * Skipping the primary key check.
       */

      const linkUrl = useEndpoints.organization.update(this.primaryKey)

      const linkMethod = 'PATCH'
      console.log('pk', this.primaryKey)

      const linkData = new FormData()
      for (const k in this.form.link) {
        console.log('link', k, this.form.link[k])
        linkData.append(k, this.form.link[k])
      }

      const linkReq = this.$http({
        url: linkUrl,
        method: linkMethod,
        data: linkData,
      })

      /**
       * IoT types
       *
       * in draft status -> the iot types might not been created,
       * meaning there's iot types for organization from request, in that case, types need to be created.
       * Also, in editing mode, removed iot's need to be deleted to sync the data
       */
      let iotTypeRequests = []
      if (this.lockTypes.length) {
        this.lockTypes.forEach((element) => {
          console.log(`Elem ${element.type}`, element)
          const isUpdateReq = 'id' in element
          const iotTypeMethod = isUpdateReq ? 'PATCH' : 'POST'
          const iotTypeUrl = isUpdateReq
            ? useEndpoints.organization.vehicleTypeSettings.update(element.id)
            : useEndpoints.organization.vehicleTypeSettings.create(
                this.primaryKey
              )

          const iotData = new FormData()
          iotData.append('iot_category', element.type)

          if (typeof element.domain === 'string')
            iotData.append('domain', element.domain)

          if (element.port) {
            if (`${element.port}`.trim() === '' || !isNaN(element.port)) {
              iotData.append('port', element.port)
            }
          }
          // console.log(`${element.type}`, iotData)

          const iotReq = this.$http({
            url: iotTypeUrl,
            method: iotTypeMethod,
            data: iotData,
          })
          iotTypeRequests.push(iotReq)
        })
      }

      if (this.isEditing && this.toDeleteLockTypes.length) {
        this.toDeleteLockTypes.forEach((element) => {
          if ('id' in element) {
            const iotReq = this.$http({
              url: useEndpoints.organization.vehicleTypeSettings.update(
                element.id
              ),
              method: 'DELETE',
            })
            iotTypeRequests.push(iotReq)
          }
        })
      }

      const requests = [paymentReq, regularReq, linkReq, ...iotTypeRequests]

      // submit
      this.isLoading = true
      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            const [paymentRes, regularRes, linkRes, ...iotTypeResps] = responses
            console.log('iotTypeResps', iotTypeResps.length)
            // let iotTypeData = iotTypeResps.map((res) => res.data?.data)
            const data = {
              payment: paymentRes.data,
              link: linkRes.data,
              regular: regularRes.data,
              // iotResps: iotTypeData,
            }

            // note raw responses are being sent
            this.$emit('save', { step: 3, data })

            const message = `Organization setings ${
              this.isEditing ? 'updated' : 'added'
            } successfully`

            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.web-link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 42px;
  background: rgba(243, 244, 246, 1);
  border: 1px solid rgba(203, 213, 224, 1);
  margin-left: 0.25rem;
  border-radius: 0.25rem;
}
.minus-btn {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #d90a20;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.section-name {
  @apply text-sm font-bold text-gray-500;
}
</style>
