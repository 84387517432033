<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <loading :active.sync="isLoading" :is-full-page="false" />

      <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
        <!-- isEditing: {{ isEditing }} <br />
      isOrgUserCreated: {{ isOrgUserCreated }} -->
        <section>
          <!-- row-1 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.full_name"
              type="text"
              name="Full Name"
              label="Full Name"
              rules="required"
              placeholder="e.g. John Doe"
            />

            <AppInput
              v-model="form.username"
              type="text"
              name="Username"
              label="Username"
              rules="required"
              placeholder="e.g. john_doe "
            />
          </div>
          <!-- /row-1 -->

          <!-- row-2 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.phone_number"
              type="tel"
              name="Phone Number"
              rules="required"
              label="Phone Number"
              placeholder="Phone Number"
            />

            <AppInput
              v-model="form.email"
              type="email"
              name="Email"
              rules="required"
              label="Email"
              placeholder="e.g. john@example.com"
            />
          </div>
          <!-- /row-2 -->

          <!-- row-3 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.password"
              type="password"
              name="password"
              :rules="getPasswordValidationRules"
              label="Password"
              placeholder=""
            />

            <AppInput
              v-model="passwordConfirmation"
              type="password"
              name="Confirm Password"
              rules="confirmed:password"
              label="Confirm Password"
              placeholder=""
            />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.owner_expiry_date"
              type="date"
              name="Owner Expiry Date"
              label="Owner Expiry Date"
              placeholder=""
            />
          </div>
          <!-- /row-3 -->
        </section>
        <button type="submit" ref="submitButton" class="hidden">Save</button>
      </form>
    </ValidationObserver>
    <t-modal
      ref="modalRef"
      name="ownerOtp"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
    >
      <header class="py-4 px-3">
        <div class="flex justify-between modal-header">
          <div class="w-full text-left">
            <div class="px-4 text-2xl font-extrabold text-center">
              An OTP has been sent
            </div>
            <div class="px-4 font-bold text-center">
              Check {{ getOtpModalSubtitlePart }} for OTP
            </div>
          </div>
        </div>
      </header>

      <section class="w-full h-50">
        <div class="grid justify-center">
          <AppInput
            class="justify-center"
            v-model="otpInput"
            type="number"
            name=""
            label=""
            placeholder="e.g. 876540"
          />
        </div>
      </section>

      <div class="flex gap-4 justify-end py-2 px-4 bg-white modal-header">
        <AppButton
          :text="'Cancel'"
          :variant="'secondary'"
          @click="handleOtpCancel()"
        />
        <AppButton
          :text="'Confirm'"
          :variant="'primary'"
          @click="handleOtpSubmit()"
        />
      </div>
    </t-modal>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects, xMan } from '@/utils'
// import { getFormModel } from '.'

export default {
  name: 'OrgAddEditStep4',
  props: {
    orgUserId: {
      type: String,
      required: false,
    },

    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    // const { payment, link, domain } = getFormModel({ step: 3 })
    // console.log(payment, link, domain, 'form3')

    return {
      isLoading: false,
      old_phone_number: undefined,
      old_email: undefined,
      form: {
        full_name: '',
        username: '',
        email: '',
        phone_number: '',
        owner_expiry_date: '',
      },

      password: undefined,
      passwordConfirmation: undefined,
      otpInput: '',
      // isOrgUserCreated: false,
      savedFormState: {}, // used for post update hook from owner otp submit action
      org_user_id: undefined,
    }
  },
  computed: {
    isOrgUserCreated() {
      return !!this.orgUserId
    },
    getPasswordValidationRules() {
      if (!this.isOrgUserCreated) {
        return 'required|min:8'
      }

      return this.isEditing ? 'min:8' : 'required|min:8'
    },
    updatedPhoneNumber() {
      return this.old_phone_number !== this.form.phone_number
    },
    updatedEmailAddress() {
      return this.old_email !== this.form.email
    },
    getOtpModalSubtitlePart() {
      if (this.updatedEmailAddress && this.updatedPhoneNumber)
        return 'phone/email'
      else if (this.updatedEmailAddress) return 'email'
      else if (this.updatedPhoneNumber) return 'phone'
      else return ''
    },
  },
  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
          delete this.form.org_user_id

          this.old_phone_number = data.phone_number
          this.old_email = data.email
          this.org_user_id = data.org_user_id

          // this.isOrgUserCreated = !!this.form.username
          console.log(this.form, this.isOrgUserCreated, 'ff4')
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 4, data: this.form })
      },
    },
  },
  methods: {
    showOtpModal() {
      this.otpInput = ''
      this.$modal.show('ownerOtp')
    },
    hideOtpModal() {
      this.$modal.hide('ownerOtp')
      this.revertOtpBasedFormUpdates()
    },
    revertOtpBasedFormUpdates() {
      this.form.phone_number = this.old_phone_number
      this.form.email = this.old_email
    },
    async handleOtpSubmit() {
      if (this.otpInput.trim() == '') {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: 'Enter valid OTP',
          },
          3000
        )
        return
      }

      await this.verifyOtpForOwnerUpdate()
    },
    handleOtpCancel() {
      console.log('cancel tapped')
      this.hideOtpModal()
    },
    async requestUpdateOtpForOwner() {
      let url = `/dashboard/org-users/${this.org_user_id}/verify-update-profile/send-otp/`
      let data = new FormData()
      data.append('phone_number', this.form.phone_number)
      data.append('email', this.form.email)

      await this.$http
        .post(url, data)
        .then((res) => {
          console.log('GOT OTP REQ RES', res)
          this.showOtpModal()
        })
        .catch((err) => {
          console.log('updateStErr--', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'OTP request failed to execute.',
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async verifyOtpForOwnerUpdate() {
      let url = `/dashboard/org-users/${this.org_user_id}/verify-update-profile/verify-otp/`
      let data = new FormData()
      data.append('otp_code', this.otpInput)
      data.append('updated_email', this.updatedEmailAddress)
      data.append('updated_phone_number', this.updatedPhoneNumber)

      let self = this
      await this.$http
        .post(url, data)
        .then(async function(res) {
          console.log('GOT OTP VERIFY RES', res)
          self.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'OTP Verified!',
              text: 'Requesting for update',
            },
            3000
          )
          self.hideOtpModal()
          await self.handleRequests(
            'PATCH',
            useEndpoints.user.org.update(self.orgUserId),
            self.savedFormState
          )
        })
        .catch((err) => {
          this.revertOtpBasedFormUpdates()
          console.log('updateStErr--', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'OTP Verify request failed to execute.',
            },
            3000
          )
        })
    },
    async submit() {
      await this.$refs.submitButton.click()
    },
    async submitStep() {
      let orgUrl = ''
      let orgMethod = ''

      const formDataProxy = { ...this.form }

      // Add additional required fields
      formDataProxy.organization = this.primaryKey

      if (!this.isOrgUserCreated || !this.isEditing) {
        orgUrl = useEndpoints.user.org.create()
        orgMethod = 'POST'
        formDataProxy.org_create_mode = true
      } else {
        orgUrl = useEndpoints.user.org.update(this.orgUserId)
        orgMethod = 'PATCH'
        formDataProxy.org_edit_mode = true
      }
      console.log('url', orgUrl)
      // Add optional fields
      if (
        this.password &&
        this.password !== undefined &&
        this.password !== null &&
        `${this.password}`.trim() !== '' &&
        this.passwordConfirmation &&
        this.password === this.passwordConfirmation
      ) {
        formDataProxy.password = this.password
      }

      if (orgMethod == 'PATCH') {
        // console.log('BLOCKED PATCH | FORM DATA', formDataProxy)
        if (this.updatedPhoneNumber || this.updatedEmailAddress) {
          this.savedFormState = formDataProxy
          await this.requestUpdateOtpForOwner()
          return
        }
      }

      await this.handleRequests(orgMethod, orgUrl, formDataProxy)
    },
    async handleRequests(orgMethod, orgUrl, formDataProxy) {
      const orgData = new xMan(formDataProxy).toFormData()

      console.log({ orgMethod })

      const orgReq = this.$http({
        url: orgUrl,
        method: orgMethod,
        data: orgData,
      })

      const requests = [orgReq]

      // submit
      this.isLoading = true
      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            const [orgRes] = responses

            const data = orgRes.data

            console.log({ data })

            // note raw responses are being sent
            // NOTE: onSave() listener should save/merge owner data with rawData/somewhere
            // so when re-visiting the step without closing, it wont send create request.
            this.$emit('save', { step: 4, data })

            const message = `Organization owner ${
              this.isEditing ? 'updated' : 'added'
            } successfully`

            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err.response)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.section-name {
  @apply text-sm font-bold text-gray-500;
}
</style>
