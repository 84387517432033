<template>
  <EdgeStack :id="esId" :busy="busy">
    <template v-slot:header>
      <div class="pb-3 panel-title">{{ getTitleText }}</div>

      <XStepper
        :id="esId"
        :steps="stepperSteps"
        :mode="stepperMode"
        v-model="stepperCurrentStep"
        ref="xstepper"
      />
    </template>

    <template v-slot:footer>
      <XStepperNavigation
        v-if="isEditing"
        :id="esId"
        :steps="stepperSteps"
        :submit-button-text="getSubmitButtonText"
        @submit="onSubmit"
        v-model="stepperCurrentStep"
      />

      <AppButton
        v-if="!isEditing"
        :text="getSubmitButtonText"
        @click="onSubmit({ step: stepperCurrentStep })"
      />
    </template>

    <template #default>
      <keep-alive>
        <template v-if="stepperCurrentStep === 1">
          <OrgAddEditStep1
            ref="step1"
            :countries="countries"
            :primary-key="primaryKeyProxy"
            :form-data="getStep1FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 2">
          <OrgAddEditStep2
            ref="step2"
            :primary-key="primaryKeyProxy"
            :subscription-plans="subscriptionPlans"
            :form-data="getStep2FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 3">
          <OrgAddEditStep3
            ref="step3"
            :lock-iots="lockIots"
            :payment-gateways="paymentGateways"
            :primary-key="primaryKeyProxy"
            :form-data="getStep3FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 4">
          <OrgAddEditStep4
            ref="step4"
            :org-user-id="getOrgUserId"
            :primary-key="primaryKeyProxy"
            :form-data="getStep4FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template>
      </keep-alive>
    </template>
  </EdgeStack>
</template>

<script>
import pick from 'lodash/pick'

// import { useEndpoints } from '@/composables'

import { AppButton } from '@/components/form'
import { EdgeStack } from '@/components/modals'
import { XStepper, XStepperNavigation } from '@/components/stepper'

import OrgAddEditStep1 from './OrgAddEditStep1'
import OrgAddEditStep2 from './OrgAddEditStep2'
import OrgAddEditStep3 from './OrgAddEditStep3'
import OrgAddEditStep4 from './OrgAddEditStep4'

import { getFormModel } from './index'

import { useCountryIndex, useEndpoints } from '@/composables'

export default {
  name: 'OrgAddEdit',

  components: {
    EdgeStack,
    XStepper,
    XStepperNavigation,
    AppButton,
    OrgAddEditStep1,
    OrgAddEditStep2,
    OrgAddEditStep3,
    OrgAddEditStep4,
  },

  props: {
    esId: {
      type: String,
      default: 'org-add-edit',
    },
    stepperMode: {
      type: String,
      default: 'free',
    },
    stepperStep: {
      type: Number,
      default: 1,
    },
    primaryKey: {
      required: false,
    },
    rawData: {
      type: Object,
      required: false,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      errors: [],
      // Unless a step is free, user can't navigate to that step by clicking on the step
      // todo: also support this feature on xStepperNavigation & xStepper.navigate() helpers

      stepperSteps: [
        { title: 'Profile', free: true },
        { title: 'Subscription', free: false },
        { title: 'Settings', free: false },
        { title: 'Owner', free: false },
      ],
      // this is the proxy of prop.stepperStep. It's used as v-model for steps
      // it needs to be in sync -> watched
      stepperCurrentStep: 1,

      // these data are required for the stepper steps
      lockIots: [],
      countries: [],
      paymentGateways: [],
      subscriptionPlans: [],

      primaryKeyProxy: null,
    }
  },

  computed: {
    // User data is meant to be passed to be in edit mode
    // ? is it better to also check stepperMode? i'll think about it later
    // todo: don't allow navigating to next step in editing mode if current step is dirty
    // todo: -> need to save dirty state with step data, pass prob allowNext & allowPrev on XStepperNavigation component
    isEditing() {
      return !!this.rawData
    },

    getTitleText() {
      return (this.isEditing ? 'Update' : 'Add') + ' Organization'
    },

    getSubmitButtonText() {
      if (this.stepperCurrentStep > this.stepperSteps.length - 1)
        return 'Finish'

      return this.isEditing ? 'Update' : 'Save & Continue'
    },

    // get steps formData prop getters : either valid object or null.
    // generate from prop.userData for setting edit mode data.
    //
    // pass the computed formData to all steps in all mode & watch prop.formData in step component
    // then set $data.form if props.formData are valid object (not null)
    //
    // the returned object must resemble as the $data.form object defined in different steps
    // it'll be watched & synced in the stepX component.
    getStep1FormData() {
      if (this.rawData) {
        const modelKeys = Object.keys(getFormModel({ step: 1 }))
        const picked = pick(this.rawData, modelKeys)

        // override as per requirement
        picked.city = this.rawData.city.id
        picked.country = this.rawData.country.id

        if (this.rawData.default_fleet) {
          picked.default_fleet = this.rawData.default_fleet.id
        }

        console.log('step1', picked)

        return picked
      }

      return null
    },

    getStep2FormData() {
      if (this.rawData) {
        const modelKeys = Object.keys(getFormModel({ step: 2 }))
        const picked = pick(this.rawData, modelKeys)

        // override to have id only
        picked.subscription = this.rawData.subscription.id
        return picked
      }

      return null
    },

    getStep3FormData() {
      if (this.rawData) {
        // todo: domain
        const { payment, link, regular } = getFormModel({ step: 3 })

        const paymentData = this.rawData?.default_payment_gateway_config
        const paymentModelKeys = Object.keys(payment)

        const pickedPayment = pick(paymentData, paymentModelKeys)

        const linkModelKeys = Object.keys(link)
        const pickedLink = pick(this.rawData, linkModelKeys)

        const regularKeys = Object.keys(regular)
        const pickedRegularData = pick(this.rawData, regularKeys)

        return {
          payment: pickedPayment || {},
          paymentId: paymentData && paymentData.id ? paymentData.id : '',
          link: pickedLink || {},
          regular: pickedRegularData || {},
        }
      }

      return null
    },

    getOrgUserId() {
      if (this.rawData && this.rawData.owner_user) {
        return this.rawData.owner_user.id
      }

      return null
    },

    getStep4FormData() {
      if (!this.getOrgUserId) {
        console.log('noOwnerUser')
        return null
      }

      const modelKeys = Object.keys(getFormModel({ step: 4 }))
      const picked = pick(this.rawData.owner_user, modelKeys)
      console.log({ p4: picked })

      let sanitizedDate = picked.owner_expiry_date
        .split('/')
        .reverse()
        .join('-')

      picked.owner_expiry_date = sanitizedDate
      picked.org_user_id = this.getOrgUserId
      return picked
    },
  },

  async created() {
    this.countries = await useCountryIndex()
      .then(({ data }) => data)
      .catch((e) => console.log(e))

    // todo: move to useSubscriptionPlanDropdown()
    this.subscriptionPlans = await this.$http
      .get(useEndpoints.dropdown.subscriptions())
      .then((res) => res.data.data)
      .catch((err) => console.log(err))

    this.paymentGateways = await this.$http
      .get(useEndpoints.dropdown.paymentGateways())
      .then((res) => res.data.data)
      .catch((err) => console.log('paymentGatewayErr', err))

    this.lockIots = await this.$http
      .get(useEndpoints.dropdown.lockTypes())
      .then((res) => res.data.data)
      .catch((err) => console.log('lockIotsErr', err))
  },

  mounted() {
    // reset current step to 1 on closed
    this.$edgeStack.emitter.on(
      this.$edgeStack.getEventName('closed', this.esId),
      () => {
        this.stepperCurrentStep = 1
      }
    )
  },

  beforeDestroy() {
    this.$edgeStack.emitter.off(
      this.$edgeStack.getEventName('closed', this.esId)
    )
  },

  watch: {
    // $data.theUserId needs to be in sync with the prop.userData.id (getUserId),
    // it's used as a proxy var as step 1 need to be able to update it from save event (via onSave)
    //
    // It's required for all steps of edit mode & even in add mode (except step 1 add mode)
    //
    // It's also updated from the save event listener of step 1 via onSave().
    // No need to listen on other step's save event as it should be the same id.
    primaryKey: {
      immediate: true,
      handler(updatedId) {
        // primaryKey -> updatedId could be null (in add mode)
        if (updatedId) {
          this.primaryKeyProxy = updatedId
        }
      },
    },

    stepperStep: {
      deep: false,
      immediate: true,
      handler(updatedStep) {
        this.stepperCurrentStep = updatedStep
        console.log('st', this.stepperCurrentStep)
      },
    },
  },

  methods: {
    onDirty(type, id = this.esId) {
      return type === true
        ? this.$edgeStack.shouldConfirm(id)
        : this.$edgeStack.shouldNotConfirm(id)
    },

    onSave(e = { step: null, data: {} }) {
      console.log(e)
      if (e.step === 1) {
        // if it's in add mode, user shouldn't be able to re submit step 1
        // or if the api supports it, it needs to be a patch request

        // if primaryKeyProxy is already set & mode is strict -> it means user is trying
        // to get back to step 1 & resubmit

        // todo: prevent it? 1. check primary key exists in 1st step? make it a patch req

        // update primaryKeyProxy
        this.primaryKeyProxy = e.data.id

        // free the first 2 steps
        const steps = [
          { title: 'Profile', free: true },
          { title: 'Subscription', free: true },
          { title: 'Settings', free: false },
          { title: 'Owner', free: false },
        ]

        this.$xStepper.defineSteps(this.esId, steps)
        this.beforeNextStep()
      }

      if (e.step === 2) {
        // free the first 3 steps
        const steps = [
          { title: 'Profile', free: true },
          { title: 'Subscription', free: true },
          { title: 'Settings', free: true },
          { title: 'Owner', free: false },
        ]

        this.$xStepper.defineSteps(this.esId, steps)
        this.beforeNextStep()
      }

      if (e.step === 3) {
        // free all steps
        const steps = [
          { title: 'Profile', free: true },
          { title: 'Subscription', free: true },
          { title: 'Settings', free: true },
          { title: 'Owner', free: true },
        ]

        this.$xStepper.defineSteps(this.esId, steps)
        this.beforeNextStep()
      }

      if (e.step === 4) {
        // free only first step -> as we'r about to reset & close -> set it to initial state
        this.$xStepper.defineSteps(this.esId, this.stepperSteps)
        this.onLastStep()
      }
    },

    beforeNextStep() {
      // define what happens about confirmation before exiting on next step (2)
      // on edit mode->after updating->should not confirm unless event triggered by user
      // on add mode->after saving->should confirm
      if (this.stepperMode === 'free') {
        this.$edgeStack.shouldNotConfirm(this.esId)
      } else {
        this.$edgeStack.shouldConfirm(this.esId)
      }

      this.$xStepper.navigate(this.esId).next()
    },

    onLastStep() {
      // notify that the table view needs to be updated
      this.$emit('refresh')

      // Should not confirm as we'r about to close it
      this.$edgeStack.shouldNotConfirm(this.esId)
      this.$edgeStack.close(this.esId)

      this.$edgeStack.emitter.on(
        this.$edgeStack.getEventName('closed', this.esId),
        () => {
          this.stepperCurrentStep = 1
        }
      )
    },

    onSubmit(step) {
      this.$refs[`step${step.step}`].submit()
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
